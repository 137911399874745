import React from "react";
import { Navigate } from "react-router-dom";

const useAuth = () => {
  const user = localStorage.getItem("user");
  return user;
};

const PrivateRoute = () => {
  const auth = useAuth();
  return auth ? <Navigate to="/pages/publish" /> : <Navigate to="/login" />;
};

export default PrivateRoute;
