import React, { useState, useEffect } from "react";
import { UploadIcon, RefreshCwIcon, SearchIcon } from "lucide-react";
import { HOST } from "../../config";
import Sidebar from "../../Components/Sidebar";
import FileItem from "./FileItem";

const FTPClient = () => {
  const [currentPath, setCurrentPath] = useState("/");
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [copyStatus, setCopyStatus] = useState("");
  const [selectedServer, setSelectedServer] = useState(null);
  const [servers, setServers] = useState([]);

  // Search functionality
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredFiles(files);
    } else {
      const searchResults = files.filter((file) =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredFiles(searchResults);
    }
  }, [searchTerm, files]);

  async function fetchAvaliableServers() {
    try {
      const response = await fetch(`${HOST}/api/v1/admin/ftp/servers`);
      const data = await response.json();
      setServers([...data]);
    } catch (error) {
      console.error("Error fetching servers:", error);
    }
  }

  const handleServerSelect = (server) => {
    setFiles([]);
    setCurrentPath("/");
    setSelectedServer(server);
    setError(null);
    setUploadStatus("");
  };

  const navigateToFolder = (folder) => {
    if (folder === "..") {
      const newPath = currentPath.split("/").slice(0, -2).join("/") + "/";
      setCurrentPath(newPath || "/");
    } else {
      setCurrentPath(currentPath + folder + "/");
    }
  };

  const fetchFileList = async (path) => {
    if (!selectedServer) return;

    setLoading(true);
    try {
      const response = await fetch(
        `${HOST}/api/v1/admin/ftp/list?path=${encodeURIComponent(
          path
        )}&serverId=${selectedServer.id}`
      );
      if (!response.ok) throw new Error("Failed to fetch file list");
      const data = await response.json();
      data.forEach((file) => {
        if (!file.isDirectory) {
          file.path = getFilePath(file.name);
        }
      });

      setFiles(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (event) => {
    if (!selectedServer) return;

    const files = event.target.files;
    setError(null);
    setUploadStatus("");

    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      formData.append("path", currentPath);
      formData.append("serverId", selectedServer.id);

      setUploadStatus("Uploading...");

      const response = await fetch(`${HOST}/api/v1/admin/ftp/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || errorData.message || "Upload failed"
        );
      }

      setUploadStatus("Upload successful!");
      fetchFileList(currentPath);
    } catch (err) {
      setError(`Upload failed: ${err.message}`);
      setUploadStatus("");
    }
  };

  const handleRename = async (oldName, newName) => {
    try {
      const response = await fetch(`${HOST}/api/v1/admin/ftp/rename`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPath: currentPath + oldName,
          newPath: currentPath + newName,
          serverId: selectedServer.id,
        }),
      });

      if (!response.ok) throw new Error("Rename failed");
      fetchFileList(currentPath);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDownload = async (filename) => {
    try {
      const response = await fetch(
        `${HOST}/api/v1/admin/ftp/download?path=${encodeURIComponent(
          currentPath + filename
        )}&serverId=${selectedServer.id}`
      );
      if (!response.ok) throw new Error("Download failed");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (filename) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        const response = await fetch(
          `${HOST}/api/v1/admin/ftp/delete?path=${encodeURIComponent(
            currentPath + filename
          )}&serverId=${selectedServer.id}`,
          {
            method: "DELETE",
          }
        );
        if (!response.ok) throw new Error("Delete failed");
        fetchFileList(currentPath);
        setOpenMenuId(null);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleCreateFolder = async () => {
    const folderName = prompt("Enter folder name:");
    if (folderName) {
      try {
        const response = await fetch(`${HOST}/api/v1/admin/ftp/mkdir`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            path: currentPath + folderName,
            serverId: selectedServer.id,
          }),
        });

        if (!response.ok) throw new Error("Failed to create folder");
        fetchFileList(currentPath);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    if (selectedServer !== null) {
      fetchFileList(currentPath);
    }
  }, [currentPath, selectedServer]);

  useEffect(() => {
    fetchAvaliableServers();
  }, []);

  function getFilePath(filename) {
    const filePath = selectedServer.base + currentPath + filename;
    const cleanPath = filePath.replace(/^\/+|\/+$/g, ""); // Remove leading/trailing slashes
    return `https://${selectedServer.domain}/${cleanPath}`;
  }

  const handleCopyUrl = async (file) => {
    try {
      await navigator.clipboard.writeText(file.path);
      setCopyStatus("URL copied to clipboard!");

      // Clear the status message after 2 seconds
      setTimeout(() => {
        setCopyStatus("");
      }, 2000);
    } catch (err) {
      setError("Failed to copy URL to clipboard");
    }
  };

  const handleResizeImage = async (file, dimensions) => {
    if (!selectedServer) return;

    setError(null);
    setUploadStatus("");

    try {
      setUploadStatus("Resizing and uploading...");

      const response = await fetch(`${HOST}/api/v1/admin/ftp/resize`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fileUrl: file.path,
          path: currentPath,
          serverId: selectedServer.id,
          width: dimensions.width,
          height: dimensions.height,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || errorData.message || "Resize failed"
        );
      }

      setUploadStatus("Resize successful!");
      fetchFileList(currentPath);
    } catch (err) {
      setError(`Resize failed: ${err.message}`);
      setUploadStatus("");
    }
  };

  const renderBreadcrumb = () => {
    const paths = currentPath.split("/").filter(Boolean);
    return (
      <div className="flex items-center gap-1 text-sm text-gray-600">
        <span
          className="cursor-pointer hover:text-blue-500"
          onClick={() => setCurrentPath("/")}
        >
          Root
        </span>
        {paths.map((path, index) => (
          <React.Fragment key={index}>
            <span>/</span>
            <span
              className="cursor-pointer hover:text-blue-500"
              onClick={() => {
                const newPath = "/" + paths.slice(0, index + 1).join("/") + "/";
                setCurrentPath(newPath);
              }}
            >
              {path}
            </span>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const renderServerTabs = () => (
    <div className="mb-2">
      <div className="border-b border-gray-200">
        <div className="flex space-x-4">
          {servers.map((server) => (
            <button
              key={server.id}
              onClick={() => handleServerSelect(server)}
              className={`py-2 px-4 border-b-2 font-medium text-sm ${
                selectedServer?.id === server.id
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              {server.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 flex flex-col p-8">
        <h1 className="text-2xl font-bold">FTP Client</h1>
        {renderServerTabs()}

        {!selectedServer ? (
          <div className="flex-1 flex flex-col gap-6 items-center justify-center text-gray-500">
            Please select a server to continue
            <div className="grid grid-cols-2 gap-4">
              {servers.map((server) => (
                <button
                  className={`bg-${server.color}-500 px-6 py-4 rounded-lg border-2 transition-all border-gray-200 hover:border-gray-300`}
                  onClick={() => handleServerSelect(server)}
                >
                  <div className="dlex flex-col gap-4">
                    <div className="text-white text-lg font-medium">
                      {server.des}
                    </div>
                    <div className="text-white text-lg font-medium">
                      ({server.name})
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4 flex items-center justify-between">
              <div className="flex items-center gap-4 flex-1">
                <div className="text-sm text-gray-500">
                  Connected to: {selectedServer.name}
                </div>
                <div className="relative flex-1 max-w-md">
                  <input
                    type="text"
                    placeholder="Search files..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  />
                  <SearchIcon
                    className="absolute left-3 top-2.5 text-gray-400"
                    size={18}
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={handleCreateFolder}
                  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center gap-2 transition duration-150"
                >
                  New Folder
                </button>
                <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded flex items-center gap-2 transition duration-150">
                  <UploadIcon size={16} />
                  Upload
                  <input
                    type="file"
                    multiple
                    className="hidden"
                    onChange={handleUpload}
                  />
                </label>
                <button
                  onClick={() => fetchFileList(currentPath)}
                  className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded flex items-center gap-2 transition duration-150"
                >
                  <RefreshCwIcon size={16} />
                  Refresh
                </button>
              </div>
            </div>

            {uploadStatus && (
              <div className="mb-4 p-4 bg-blue-50 border border-blue-200 text-blue-700 rounded">
                {uploadStatus}
              </div>
            )}

            {copyStatus && (
              <div className="mb-4 p-4 bg-green-50 border border-green-200 text-green-700 rounded">
                {copyStatus}
              </div>
            )}

            {error && (
              <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded">
                {error}
              </div>
            )}

            <div className="flex-1 bg-white shadow-lg rounded-lg border">
              <div className="p-4 border-b">{renderBreadcrumb()}</div>

              {loading ? (
                <div className="p-8 text-center text-gray-500">Loading...</div>
              ) : (
                <div className="flex flex-wrap gap-4 p-4 mx-auto">
                  {currentPath !== "/" && !searchTerm && (
                    <FileItem
                      file={{ name: "..", isDirectory: true }}
                      onNavigate={() => navigateToFolder("..")}
                      onDelete={() => {}}
                      onRename={() => {}}
                      onDownload={() => {}}
                      onCopyUrl={() => {}}
                      isMenuOpen={false}
                      onMenuOpen={() => {}}
                      onMenuClose={() => {}}
                      isParentDirectory={true}
                    />
                  )}
                  {filteredFiles.map((file) => (
                    <FileItem
                      key={file.name}
                      file={file}
                      onDelete={handleDelete}
                      onRename={handleRename}
                      onDownload={handleDownload}
                      onNavigate={navigateToFolder}
                      onCopyUrl={() => handleCopyUrl(file)}
                      onResizeImage={handleResizeImage}
                      isMenuOpen={openMenuId === file.name}
                      onMenuOpen={() => setOpenMenuId(file.name)}
                      onMenuClose={() => setOpenMenuId(null)}
                      isParentDirectory={false}
                    />
                  ))}
                  {filteredFiles.length === 0 && (
                    <div className="col-span-full p-8 text-center text-gray-500">
                      {searchTerm
                        ? "No files match your search"
                        : "No files found in this directory"}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FTPClient;
