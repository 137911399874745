import { useState, useEffect } from "react";
import { Edit2, Save, X, Search } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { HOST } from "../../config";
import Sidebar from "../../Components/Sidebar";
import Notification from "../../Components/Notification/Notification";

export default function Blogs() {
  const { type } = useParams();

  const [blogs, setBlogs] = useState([]);
  const [newBlog, setNewBlog] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [notifications, setNotifications] = useState({
    success: "",
    error: "",
    delete: "",
  });

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    fetchBlogs();
  }, [type]); // Re-fetch when type changes

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const url =
        type === "draft"
          ? `${HOST}/api/v1/admin/blogs/draft`
          : `${HOST}/api/v1/admin/blogs/publish`;

      const request = await fetch(url);
      const response = await request.json();
      if (response) {
        setBlogs([...response].sort((a, b) => a.title.localeCompare(b.title)));
      }
    } catch (err) {
      setError(`Failed to fetch ${type} blogs`);
      console.error(`Error fetching ${type} blogs:`, err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddBlog = async (e) => {
    e?.preventDefault();
    if (newBlog.trim() === "") return;

    if (
      blogs.some(
        (blog) => blog.title.toLowerCase() === newBlog.trim().toLowerCase()
      )
    ) {
      setError("Blog already exists");
      return;
    }

    try {
      setIsAdding(true);
      const request = await fetch(`${HOST}/api/v1/admin/blog`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          title: newBlog.trim(),
          type,
        }),
      });
      const response = await request.json();

      if (response) {
        setBlogs((prevBlogs) =>
          [...prevBlogs, response].sort((a, b) =>
            a.title.localeCompare(b.title)
          )
        );
        setNewBlog("");
        setError("");
        setSuccessMessage(
          `${type === "draft" ? "Draft" : "Blog"} added successfully`
        );
      }
    } catch (err) {
      setError(err.response?.data?.message || `Failed to add ${type} blog`);
    } finally {
      setIsAdding(false);
    }
  };

  const handleEditBlog = async (id, e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (editValue.trim() === "") return;

    if (
      blogs.some(
        (blog) =>
          blog.id !== id &&
          blog.title.toLowerCase() === editValue.trim().toLowerCase()
      )
    ) {
      setError("Blog title already exists");
      return;
    }

    try {
      const request = await fetch(`${HOST}/api/v1/admin/blog`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id,
          title: editValue.trim(),
          type,
        }),
      });
      const response = await request.json();

      if (response) {
        setBlogs((prevBlogs) =>
          prevBlogs
            .map((blog) =>
              blog.id === id ? { ...blog, title: editValue.trim() } : blog
            )
            .sort((a, b) => a.title.localeCompare(b.title))
        );
        setEditingId(null);
        setEditValue("");
        setError("");
        showNotification(
          "success",
          `${type === "draft" ? "Draft" : "Blog"} updated successfully`
        );
      }
    } catch (err) {
      setError(err.response?.data?.message || `Failed to update ${type} blog`);
    }
  };

  // const handleDeleteBlog = async (id, e) => {
  //   e?.preventDefault();
  //   e?.stopPropagation();
  //   if (
  //     !window.confirm(
  //       `Are you sure you want to delete this ${
  //         type === "draft" ? "draft" : "published"
  //       } blog?`
  //     )
  //   )
  //     return;

  //   try {
  //     const res = await fetch(`${HOST}/api/v1/admin/blog`, {
  //       method: "DELETE",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ id, type }),
  //     });

  //     if (res.status != 204) throw Error("Could not delete this Blog");
  //     setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== id));
  //     setError("");
  //     showNotification(
  //       "delete",
  //       `${type === "draft" ? "Draft" : "Blog"} deleted successfully`
  //     );
  //   } catch (err) {
  //     setError(err.response?.data?.message || `Failed to delete ${type} blog`);
  //   }
  // };

  const handleToggleStatus = async (blog, e) => {
    e?.preventDefault();
    e?.stopPropagation();

    try {
      const newStatus = type === "draft" ? "published" : "draft";
      const request = await fetch(
        `${HOST}/api/v1/admin/blogs/${blog.id}/status`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ status: newStatus }),
        }
      );
      const response = await request.json();

      if (response) {
        // Remove the blog from the current list since its status changed
        setBlogs((prevBlogs) => prevBlogs.filter((b) => b.id !== blog.id));
        showNotification(
          "success",
          `${
            type === "draft" ? "Draft published" : "Blog moved to drafts"
          } successfully`
        );
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update blog status");
    }
  };

  // Rest of the handler functions remain the same
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && newBlog.trim() !== "") {
      handleAddBlog();
    }
  };

  const handleEditKeyPress = (e) => {
    if (e.key === "Enter" && editValue.trim() !== "") {
      handleEditBlog(editingId, e);
    }
    if (e.key === "Escape") {
      setEditingId(null);
    }
  };

  const startEdit = (blog, e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditingId(blog.id);
    setEditValue(blog.title);
  };

  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const showNotification = (type, message) => {
    setNotifications((prev) => ({ ...prev, [type]: message }));
    const timer = setTimeout(() => {
      setNotifications((prev) => ({ ...prev, [type]: "" }));
    }, 3000);
    return () => clearTimeout(timer);
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 transition-all duration-300 ease-in-out">
        <div className="m-2 max-h-screen bg-white rounded-xl shadow-lg overflow-scroll">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="border-b border-gray-200 bg-gray-50 p-6">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {type === "draft" ? "Draft" : "Published"} Articles
                </h1>
                <p className="mt-1 text-sm text-gray-500">
                  Add, edit, or remove{" "}
                  {type === "draft" ? "Draft" : "Published"} Articles
                </p>
              </div>
            </div>

            <div className="p-6 space-y-6">
              <Notification type="error" message={notifications.error} />
              <Notification type="success" message={notifications.success} />
              <Notification type="delete" message={notifications.delete} />

              {successMessage && (
                <div className="flex items-center p-4 bg-green-50 border-l-4 border-green-400 rounded-md">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-green-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-green-700">{successMessage}</p>
                  </div>
                </div>
              )}

              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="block w-full pl-10 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder={`Search ${
                    type === "draft" ? "drafts" : "blogs"
                  }...`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              {loading ? (
                "Loading..."
              ) : (
                <div className="space-y-3">
                  {filteredBlogs.map((blog) => (
                    <div
                      key={blog.id}
                      className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all duration-200"
                    >
                      {editingId === blog.id ? (
                        <form
                          onSubmit={(e) => handleEditBlog(blog.id, e)}
                          className="flex-1 flex gap-2"
                        >
                          <input
                            type="text"
                            className="flex-1 px-3 py-2 border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            onKeyUp={handleEditKeyPress}
                            autoFocus
                          />
                          <button
                            type="submit"
                            className="p-2 text-green-600 hover:bg-green-50 rounded-md transition-colors duration-200"
                            title="Save"
                          >
                            <Save className="h-5 w-5" />
                          </button>
                          <button
                            type="button"
                            onClick={() => setEditingId(null)}
                            className="p-2 text-gray-600 hover:bg-gray-200 rounded-md transition-colors duration-200"
                            title="Cancel"
                          >
                            <X className="h-5 w-5" />
                          </button>
                        </form>
                      ) : (
                        <>
                          <Link
                            to={`/pages/${type}/${blog.id}`}
                            className="flex-1 text-gray-900 font-medium hover:text-indigo-600"
                          >
                            {blog.title}
                          </Link>
                          <div className="flex items-center gap-2">
                            <button
                              onClick={(e) => handleToggleStatus(blog, e)}
                              className="px-3 py-1 text-sm bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-200"
                            />
                            <span className="bg-blue-100 rounded-lg px-2 py-1">{blog.catnames}</span>
                            <button
                              onClick={(e) => startEdit(blog, e)}
                              className="p-2 text-blue-600 hover:bg-blue-50 rounded-md transition-colors duration-200"
                              title="Edit"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {filteredBlogs.length === 0 && !loading && (
                    <div className="text-center py-8">
                      <div className="text-gray-400">
                        {searchTerm
                          ? "No matching items found"
                          : `No ${type === "draft" ? "drafts" : "blogs"} yet`}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
