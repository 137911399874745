import React, { useEffect, useState } from "react";
import { Mail, Phone, MessageSquare, Ticket, User, Timer } from "lucide-react";
import Loader from "../../Components/Loader/Loader";
import Sidebar from "../../Components/Sidebar";
import { fetchContactUsQueries } from "../../api/Helper";
import { formatDate, formatTime } from "../../Utilities/Methords";

export default function ContactUs() {
  if (localStorage.getItem("user") !== "saurabh_llm") {
    window.location.href = "/pages/publish";
  }
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(false);

  function updateEnquiries() {
    setLoading(true);
    fetchContactUsQueries()
      .then((data) => setEnquiries(data))
      .catch(() => {
        alert("Failed to fetch enquiries. Please try again later.");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => updateEnquiries(), []);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 overflow-hidden">
        <div className="p-8">
          <div className="mb-8">
            <h1 className="text-2xl font-bold text-gray-800">
              Contact Enquiries
            </h1>
            <p className="mt-2 text-gray-600">
              View and Review customer enquiries
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <Loader />
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-2">
                          <Timer className="w-4 h-4" />
                          <span>Date & Time</span>
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-2">
                          <Ticket className="w-4 h-4" />
                          <span>Ticket</span>
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-2">
                          <User className="w-4 h-4" />
                          <span>Full Name</span>
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-2">
                          <Mail className="w-4 h-4" />
                          <span>Email</span>
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-2">
                          <Phone className="w-4 h-4" />
                          <span>Number</span>
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-2">
                          <MessageSquare className="w-4 h-4" />
                          <span>Subject & Message</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {enquiries.map((enquiry) => (
                      <tr
                        key={enquiry.ticketId}
                        className="hover:bg-gray-50 transition-colors duration-150 ease-in-out"
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600">
                          {`${formatDate(enquiry.createdAt)} & ${formatTime(
                            enquiry.createdAt
                          )}`}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600">
                          {enquiry.ticketId}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {enquiry.firstName} {enquiry.middleName}{" "}
                          {enquiry.lastName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <a
                            href={`mailto:${enquiry.email}`}
                            className="hover:text-blue-600"
                          >
                            {enquiry.email}
                          </a>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <a
                            href={`tel:${enquiry.phoneNumber}`}
                            className="hover:text-blue-600"
                          >
                            {enquiry.phoneNumber}
                          </a>
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          <div className="max-w-md">
                            <p className="font-medium text-gray-900 mb-1">
                              {enquiry.subject}
                            </p>
                            <p className="text-gray-500 truncate">
                              {enquiry.message}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {enquiries.length === 0 && (
                  <div className="text-center py-12">
                    <MessageSquare className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No enquiries
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      New enquiries will appear here
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
