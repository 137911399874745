import React, { useState, useEffect, useRef } from "react";
import {
  MoreVertical,
  Pencil,
  Trash,
  Download,
  Folder,
  File,
  Link,
  Maximize2,
} from "lucide-react";

const ContextMenu = ({
  isOpen,
  position,
  onDelete,
  onRename,
  onDownload,
  onCopyUrl,
  onResize,
  isDirectory,
  isImage,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed bg-white rounded-lg shadow-lg py-1 z-50 min-w-[160px] border border-gray-200"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      <button
        onClick={onRename}
        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
      >
        <Pencil size={16} />
        Rename
      </button>

      {!isDirectory && (
        <>
          {isImage && (
            <button
              onClick={onResize}
              className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
            >
              <Maximize2 size={16} />
              Resize
            </button>
          )}
          <button
            onClick={onDownload}
            className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
          >
            <Download size={16} />
            Download
          </button>
          <button
            onClick={onCopyUrl}
            className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
          >
            <Link size={16} />
            Copy URL
          </button>
        </>
      )}

      <button
        onClick={onDelete}
        className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50 flex items-center gap-2"
      >
        <Trash size={16} />
        Delete
      </button>
    </div>
  );
};

const FileItem = ({
  file,
  onDelete,
  onRename,
  onDownload,
  onNavigate,
  onCopyUrl,
  onResizeImage,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  isParentDirectory = false,
}) => {
  const [contextMenuPos, setContextMenuPos] = useState({ x: 0, y: 0 });
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState(file.name);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const [isResizeModal, setIsResizeModal] = useState(false);
  const [newDimensions, setNewDimensions] = useState(dimensions);

  const renameInputRef = useRef(null);
  const imgRef = useRef(null);

  const isImage = file.name
    .toLowerCase()
    .match(/\.(jpg|jpeg|png|gif|bmp|webp)$/);

  useEffect(() => {
    if (isRenaming) {
      renameInputRef.current?.focus();
      renameInputRef.current?.select();
    }
  }, [isRenaming]);

  useEffect(() => {
    if (isImage && imgRef.current) {
      const updateDimensions = () => {
        setDimensions({
          width: imgRef.current.naturalWidth,
          height: imgRef.current.naturalHeight,
        });
      };

      if (imgRef.current.complete) {
        updateDimensions();
      } else {
        const img = imgRef.current;
        img.onload = updateDimensions;
        return () => {
          img.onload = null;
        };
      }
    }
  }, [isImage, file.path]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isMenuOpen) {
        onMenuClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen, onMenuClose]);

  const handleContextMenu = (e) => {
    if (!isParentDirectory) {
      e.preventDefault();
      setContextMenuPos({
        x: e.clientX,
        y: e.clientY,
      });
      onMenuOpen();
    }
  };

  const handleRenameSubmit = (e) => {
    e.preventDefault();
    if (newName && newName !== file.name) {
      onRename(file.name, newName);
    }
    setIsRenaming(false);
  };

  const handleClick = (e) => {
    if (file.isDirectory) {
      onNavigate(file.name);
    }
  };

  const handleMoreClick = (e) => {
    e.stopPropagation();
    const rect = e.currentTarget.getBoundingClientRect();
    setContextMenuPos({
      x: rect.left,
      y: rect.bottom + 5,
    });
    onMenuOpen();
  };

  const handleResize = (e) => {
    e.preventDefault();
    onResizeImage(file, newDimensions);
    setIsResizeModal(false);
  };

  return (
    <>
      {isResizeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white relative border-2 rounded-lg p-4 max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Resize Image</h2>
            <form onSubmit={handleResize}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Width (px)
                  </label>
                  <input
                    type="number"
                    value={newDimensions.width}
                    onChange={(e) =>
                      setNewDimensions((prev) => ({
                        ...prev,
                        width: parseInt(e.target.value) || 0,
                      }))
                    }
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                    min="1"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Height (px)
                  </label>
                  <input
                    type="number"
                    value={newDimensions.height}
                    onChange={(e) =>
                      setNewDimensions((prev) => ({
                        ...prev,
                        height: parseInt(e.target.value) || 0,
                      }))
                    }
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                    min="1"
                  />
                </div>
              </div>
              <p className="text-sm text-gray-500 mt-2">
                Current dimensions: {dimensions.width}x{dimensions.height}px
              </p>
              <div className="mt-4 flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setIsResizeModal(false)}
                  className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Resize
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="flex w-80 border-2 border-blue-200 rounded-lg">
        <div
          className={`w-full p-4 flex flex-col items-center gap-4 hover:bg-gray-50 cursor-pointer transition duration-150 relative ${
            isMenuOpen ? "bg-gray-50" : ""
          }`}
          onContextMenu={handleContextMenu}
          onClick={handleClick}
        >
          <div className="flex-1">
            {file.isDirectory ? (
              <Folder className="text-blue-500 w-full h-full" />
            ) : isImage ? (
              <div className="h-32 overflow-hidden rounded bg-gray-100">
                <img
                  ref={imgRef}
                  src={file.path}
                  className="w-full h-full object-cover"
                  alt={file.name}
                />
              </div>
            ) : (
              <File className="text-gray-500 w-full h-full" />
            )}
          </div>

          <div className="max-w-full min-w-0">
            {isRenaming ? (
              <form
                onSubmit={handleRenameSubmit}
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  ref={renameInputRef}
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="w-full px-2 py-1 border rounded"
                  onBlur={() => {
                    setIsRenaming(false);
                    setNewName(file.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setIsRenaming(false);
                      setNewName(file.name);
                    }
                  }}
                />
              </form>
            ) : (
              <>
                <p className="font-medium truncate">{file.name}</p>
                {file.size && !file.isDirectory && (
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-gray-500">
                      {(file.size / 1024).toFixed(2)} KB
                    </p>
                    {!isParentDirectory && (
                      <button
                        className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                        onClick={handleMoreClick}
                      >
                        <MoreVertical size={16} className="text-gray-500" />
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {!isParentDirectory && (
          <ContextMenu
            isOpen={isMenuOpen}
            position={contextMenuPos}
            onClose={onMenuClose}
            onDelete={() => {
              onDelete(file.name);
              onMenuClose();
            }}
            onRename={() => {
              setIsRenaming(true);
              onMenuClose();
            }}
            onResize={() => setIsResizeModal(true)}
            onDownload={() => {
              onDownload(file.name);
              onMenuClose();
            }}
            onCopyUrl={() => {
              onCopyUrl(file.name);
              onMenuClose();
            }}
            isDirectory={file.isDirectory}
            isImage={isImage}
          />
        )}
      </div>
    </>
  );
};

export default FileItem;
