import React, { useEffect, useState } from "react";
import { Edit2, Save, Link as LinkIcon } from "lucide-react";
import Loader from "../../Components/Loader/Loader";
import Sidebar from "../../Components/Sidebar";
import { fetchBrokersLinks, postBrokers } from "../../api/Helper";

const BrokerLinks = () => {
  if (localStorage.getItem("user") !== "saurabh_llm") {
    window.location.href = "/pages/publish";
  }

  const [brokers, setBrokers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleUpdateLink = (event) => {
    let newBrokers = [...brokers];
    newBrokers[editingIndex].link = event.target.value;
    setBrokers([...newBrokers]);
  };

  function updateBrokers() {
    setLoading(true);
    fetchBrokersLinks()
      .then((brokers) => setBrokers(brokers))
      .catch(() => alert("Failed to get Data from Server"))
      .finally(() => setLoading(false));
  }

  const handleSave = () => {
    setLoading(true);
    postBrokers(brokers)
      .then(() => setEditingIndex(null))
      .catch(() => alert("Failed to Update Links"))
      .finally(() => setLoading(false));
  };

  useEffect(() => updateBrokers(), []);

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-800">Manage Broker Links</h1>
          <p className="mt-2 text-gray-600">Update and manage broker referral links</p>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <Loader />
            </div>
          ) : (
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Link
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {brokers.map((user, index) =>
                  editingIndex === index ? (
                    <tr key={user.userId} className="bg-blue-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {user.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center space-x-2">
                          <LinkIcon className="w-4 h-4 text-gray-400" />
                          <input
                            value={user.link}
                            onChange={handleUpdateLink}
                            className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm"
                            placeholder="Enter broker link..."
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <button
                          onClick={handleSave}
                          className="flex items-center space-x-1 text-green-600 hover:text-green-800 transition-colors duration-150"
                        >
                          <Save className="w-4 h-4" />
                          <span>Save</span>
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr key={user.userId} className="hover:bg-gray-50 transition-colors duration-150">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {user.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex items-center space-x-2">
                          <LinkIcon className="w-4 h-4 text-gray-400" />
                          <span className="truncate max-w-md">{user.link}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <button
                          onClick={() => setEditingIndex(index)}
                          className="flex items-center space-x-1 text-blue-600 hover:text-blue-800 transition-colors duration-150"
                        >
                          <Edit2 className="w-4 h-4" />
                          <span>Edit</span>
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
          {brokers.length === 0 && !loading && (
            <div className="text-center py-12">
              <LinkIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No brokers found</h3>
              <p className="mt-1 text-sm text-gray-500">Broker links will appear here</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrokerLinks;